import { Component, OnInit, Optional } from "@angular/core";
import { TalentService } from "../talent.service";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";

@Component({
  selector: "app-ai-summary",
  templateUrl: "./ai-summary.component.html",
  styleUrls: ["./ai-summary.component.css"],
})
export class AiSummaryComponent implements OnInit {
  upload_file: any;
  output_summary: any;
  isGenerating: boolean = false;
  ai_version: string = "gpt-3.5-turbo";
  ai_version_selection: any = ["gpt-3.5-turbo", "gpt-4", 'gpt-4o', 'gpt-4o-mini'];
  run_time: number = 0;
  generatedPdf: any;
  generatedPdfFileName: any;
  resume_action: string = "Resume Summary";
  resume_action_selection: any = ["Resume Summary", "Formatted Resume"];
  resume_report_list: any;
  show_report: boolean = false;
  show_generator: boolean = true;
  show_report_detail: Array<Boolean> = [];
  message: any;
  prompt = "I want a nicely formatted professional resume from following content. The name should be on it's own line. The summary of skills and knowledge should be on top, following by each work experience with most recent work experience first. Each work experience should include the company name and worked year, with both the company name and worked year in bold and the year right-aligned. The education section should be towards the end. The output text should be in html format with appropriate css. Use bullets where necessary. Do not include phone, email or linked in urls in the resume"
  isHighlighted: { [id: string]: boolean } = {};
  isGeneratingPdf :boolean =false

  constructor(private talentService: TalentService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getResumeReport()
  }

  getResumeReport() {
    this.talentService.checkResumeStatus().subscribe(response => {
      this.resume_report_list = response;
      this.show_report_detail = new Array(response.length + 1).fill(false);
      console.log('response for resume', response)
    })
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  formatTime(lastModified) {
    let modified_date = new Date(lastModified);
    var curr_date = modified_date.getDate();
    var curr_month = modified_date.getMonth() + 1; //Months are zero based
    var curr_year = modified_date.getFullYear();
    return curr_year + "-" + curr_month + "-" + curr_date;
  }

  fileUpload(event) {
    this.upload_file = event.target.files[0];
    this.isGenerating = false;
    this.output_summary = null;
  }

  fileDrop(event) {
    this.upload_file = event[0];
  }

  generateAISummary() {
    this.isGenerating = true;
    this.output_summary = null;
    let current_time = new Date();
    this.generatedPdf = "";
    let fileUploadFormData = new FormData();
    fileUploadFormData.append("file", this.upload_file);
    fileUploadFormData.append("ai_version", this.ai_version);
    fileUploadFormData.append("request_type", this.resume_action == 'Resume Summary' ? 'summary' : 'formatted_resume')
    fileUploadFormData.append("prompt", this.prompt);
    this.talentService.generateAIResumeSummary(fileUploadFormData).subscribe(
      (res) => {
        if (this.resume_action == 'Formatted Resume') {
          this.message = "Resume is being generated. Please check the Report tab."
        }
        else {
          this.output_summary = res;

        }
        this.isGenerating = false;
        this.run_time = new Date().getTime() - current_time.getTime();
        this.run_time = Math.round(this.run_time / 100) / 10;
      },
      (error) => {
        window.alert("AI Resume Generation Failed...");
        this.isGenerating = false;
      }
    );
  }

  generateAISummaryPdf(report_id, html_file_id) {

    // this.isGenerating = true;
    this.isGeneratingPdf =true;
    this.generatedPdf = "";
    this.talentService.generateAIResumeSummaryPdf({ 'content': this.output_summary, report_id: report_id, html_file_id: html_file_id }).subscribe(
      (response) => {
        this.getResumeReport()
        this.isGeneratingPdf = false;
        this.generatedPdf = new Blob([response], { type: 'application/octet-stream' });;
      },

      (error) => {
        window.alert("AI Pdf Resume Generation Failed...");
        this.isGenerating = false;
      }
    )
  }

  handleClick(filename, downloadName) {
    this.talentService.getPdfContent({ filename: filename }).subscribe(
      response => {
        this.generatedPdf = response
        // const blob = new Blob([this.generatedPdf ], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response);
        downloadLink.download = downloadName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

    )


  }

  toggleHighlight(resumeId: string) {
    this.isHighlighted[resumeId] = !this.isHighlighted[resumeId];
  }

  copyToClipboard(val: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  getFile(filename, type) {
    this.talentService.getHtmlContent({ filename: filename }).subscribe(
      response => {
        if (type == 'html') {
          this.output_summary = response
        }
        else {
          this.generatedPdf = response
          this.generatedPdfFileName = type
        }
      }
    )
  }

  showReport() {
    this.getResumeReport()
    this.show_report = true
    this.show_generator = false
    this.message=''
  }
  showGenerator() {
    this.output_summary = ""
    this.show_report = false
    this.show_generator = true
  }
  detail_toggle(id, filename): void {
    if (this.show_report_detail[id] || filename == null) {
      this.show_report_detail[id] = false;
      this.output_summary = ""
      this.generatedPdf = false
    }
    else {
      this.getFile(filename, 'html')
      this.show_report_detail[id] = !this.show_report_detail[id];
    }
  }
  saveHtml(file_id) {
    this.talentService.udpateHtmlContent({ file_id: file_id, summary: this.output_summary })
      .subscribe(response => {
        let title = "Confirmation";
        let message = "Resume changes saved successfully";
        let confirm_data = { title: title, type: "info", message: message };
        const dialogRef = this.dialog.open(CustomDialogComponent, {
          maxWidth: "600px",
          data: confirm_data,
        });
      })

  }
}


