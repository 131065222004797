import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Inject,
  Optional,
} from "@angular/core";
import { TalentService } from "../talent.service";
import { MatChipInputEvent } from "@angular/material/chips";
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { FormControl } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MatIconModule } from "@angular/material/icon";
import { CustomDialogComponent } from "../custom-dialog/custom-dialog.component";
import { PipelineOption } from "../classes/RolePipeline";
import { AiDefaults } from "../classes/AiSummary";

@Component({
  selector: "role-detail-edit",
  templateUrl: "./role-detail-edit.component.html",
  styleUrls: ["./role-detail-edit.component.css"],
})
export class RoleDetailEditComponent implements OnInit {
  @Input() initialDetails: Object;
  @Input() clients_list: any;
  @Output() doneWithSave = new EventEmitter<boolean>();
  roleDetails: any = {};
  statuses: any = [];
  categories: any = [];
  types: any = [];
  work_permission_choices = [];
  work_permission_name_by_id = {};
  location_states: any = [];
  sourcer_options: any = [];
  priority_options: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  currency_types: any = [];
  skills: any = [];
  business_knowledge: any = [];
  selected_skills: any = [];
  selected_business_knowledge: any = [];
  selected_client: any = [];
  selectable = true;
  removable = true;
  create_new: boolean = false;
  skillsCtrl = new FormControl();
  clientId: any;
  clientCtrl: FormControl = new FormControl("");
  clientFilterCtrl: FormControl = new FormControl();
  filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;

  contacts: any;
  contactCtrl: FormControl = new FormControl();
  contactFilterCtrl: FormControl = new FormControl();
  filteredContacts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild("singleSelectContact", { static: true })
  singleSelectContact: MatSelect;

  clientFilteredOptions: Observable<string[]>;
  businessKnowledgeCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredSkills: Observable<Object[]>;
  filteredBusinessKnowledge: Observable<Object[]>;
  clients: String[] = [];
  client_filter: String = "";
  @ViewChild("skillInput", { static: false })
  skillInput: ElementRef<HTMLInputElement>;
  @ViewChild("businessKnowledgeInput", { static: false })
  businessKnowledgeInput: ElementRef<HTMLInputElement>;
  @ViewChild("clientInput", { static: false })
  clientInput: ElementRef<HTMLInputElement>;
  practice_groups: any = [];
  pipelineDropdown: boolean = false;
  pipelineOptions: PipelineOption[] = []
  modelOptions: string[] = []
  aiDefaults: AiDefaults;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;
  // @ViewChild('autoBusinessKnowledge') matAutocomplete: MatAutocomplete;
  protected _onDestroy = new Subject<void>();

  constructor(
    private talentService: TalentService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.initialDetails) {
      console.log('inital Details', data.initialDetails)
      this.roleDetails = Object.assign({}, data.initialDetails);
      if (data.initialDetails["skills"]) {
        this.selected_skills = data.initialDetails["skills"].slice();
      }
      if (data.initialDetails["business_knowledge"]) {
        this.selected_business_knowledge =
          data.initialDetails["business_knowledge"].slice();
      }
      if (data.initialDetails["client"]) {
        let client = data.initialDetails["client"];
        this.clientCtrl.setValue(client);
      }
      this.create_new = data.create_new;
    }
  }

  ngOnInit() {
    if (this.initialDetails) {
      console.log('inital Details', this.initialDetails)
      this.roleDetails = Object.assign({}, this.initialDetails);
      this.selected_skills = this.initialDetails["skills"].slice();
      this.clientCtrl.setValue(this.initialDetails["client"]);
      this.selected_business_knowledge =
        this.initialDetails["business_knowledge"].slice();
    }
    // this.talentService.getSourcer()
    // .subscribe(result=>{
    //   this.sourcer_options = result;
    // });

    this.talentService.getYodaweUsers().subscribe((result) => {
      this.sourcer_options = result;
    });
    this.talentService.getRoleStatuses().subscribe((result) => {
      this.statuses = result;
    });
    this.talentService.getRoleTypes().subscribe((result) => {
      this.types = result;
    });
    this.talentService.getRolePracticeGroups().subscribe((result) => {
      this.practice_groups = result;
    });
    this.talentService.getRoleCategories().subscribe((result) => {
      this.categories = result;
    });
    this.talentService.getDisplayYesNo().subscribe((result) => {
      this.work_permission_choices = result;
      for (let wp_info of result) {
        this.work_permission_name_by_id[wp_info["id"]] = wp_info["name"];
      }
    });
    this.talentService.getLocationStates().subscribe((result) => {
      this.location_states = result;
    });
    this.talentService.getCurrencyTypes().subscribe((result) => {
      this.currency_types = result;
    });
    this.talentService.getSkills().subscribe((result) => {
      this.skills = result;
      this.filteredSkills = this.skillsCtrl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) =>
          value
            ? this._filterSkill(value)
            : this.skills.filter(
                (n) =>
                  this.selected_skills
                    .map((selected) => selected.id)
                    .indexOf(n.id) === -1
              )
        )
      );
    });

    this.talentService.getPipelineColours().subscribe((result) => {
      this.pipelineOptions = result as PipelineOption[];
    })

    this.talentService.getAiOptions().subscribe((result) => {
      this.modelOptions = result
      console.log('model options', this.modelOptions)
    })

    this.talentService.getAiDefaults().subscribe((result) => {
      this.aiDefaults = result
      if(!this.roleDetails.analysis_prompt){
        this.roleDetails.analysis_prompt = this.aiDefaults.prompt
      }
      if(!this.roleDetails.analysis_model){
        this.roleDetails.analysis_model = this.aiDefaults.model
      }
      console.log('defaults', this.aiDefaults)
    })

    if (this.clients_list) {
      for (let c of this.clients_list) {
        if (c["company_name"] == this.roleDetails.client) {
          this.clientId = c["id"];
        }
      }
      this.clients = Array.from(
        this.clients_list,
        (client) => client["company_name"]
      );
      this.filteredClient.next(this.clients.slice());
    } else {
      this.talentService.getYodaweClients().subscribe((yodawe_client) => {
        for (let c of yodawe_client) {
          if (c["company_name"] == this.roleDetails.client) {
            this.clientId = c["id"];
          }
        }
        this.clients = Array.from(
          yodawe_client,
          (client) => client["company_name"]
        );
        this.filteredClient.next(this.clients.slice());
      });
    }

    this.clientFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterClients();
      });

    this.talentService.getContactsBasic(0).subscribe((contacts) => {
      let contact_id = -1;
      if (this.initialDetails && this.initialDetails["contact_id"]) {
        contact_id = this.initialDetails["contact_id"];
      }
      let contact_list = [];
      for (let c of contacts) {
        c["contact_detail"] =
          c["name"] + " - " + c["company_name"] + " - " + c["title"];
        contact_list.push(c);
        if (c["id"] == contact_id) {
          this.contactCtrl.setValue(c);
        }
      }

      this.contacts = contact_list;
      this.filteredContacts.next(this.contacts.slice());
    });

    this.contactFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterContacts();
      });

    this.talentService.getBusinessKnowledge().subscribe((result) => {
      this.business_knowledge = result;
      this.filteredBusinessKnowledge =
        this.businessKnowledgeCtrl.valueChanges.pipe(
          startWith(null),
          map((value: string | null) =>
            value
              ? this._filterBusinessKnowledge(value)
              : this.business_knowledge.filter(
                  (n) =>
                    this.selected_business_knowledge
                      .map((selected) => selected.id)
                      .indexOf(n.id) === -1
                )
          )
        );
    });
  }

  ngAfterViewInit() {
    this.setInitialValueClient();
    this.setInitialValueContact();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredClients are loaded initially
   */
  protected setInitialValueClient() {
    this.filteredClient
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a === b;
      });
  }

  protected setInitialValueContact() {
    this.filteredContacts
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelectContact.compareWith = (a, b) =>
          a && b && a.id === b.id;
      });
  }

  selectedSkill(event: MatAutocompleteSelectedEvent): void {
    this.selected_skills.push({
      id: event.option.value,
      name: event.option.viewValue,
    });
    this.skillInput.nativeElement.value = "";
    this.skillsCtrl.setValue(null);
  }

  protected filterClients() {
    if (!this.clients) {
      return;
    }
    // get the search keyword
    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClient.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredClient.next(
      this.clients.filter((client) => client.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterContacts() {
    if (!this.contacts) {
      return;
    }
    // get the search keyword
    let search = this.contactFilterCtrl.value;
    if (!search) {
      this.filteredContacts.next(this.contacts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredContacts.next(
      this.contacts.filter(
        (contact) => contact.contact_detail.toLowerCase().indexOf(search) > -1
      )
    );
  }

  clearContact() {
    this.contactCtrl.setValue(null);
  }

  clearClient() {
    this.clientCtrl.setValue(null);
  }

  selectedBusinessKnowledge(event: MatAutocompleteSelectedEvent): void {
    this.selected_business_knowledge.push({
      id: event.option.value,
      name: event.option.viewValue,
    });
    this.businessKnowledgeInput.nativeElement.value = "";
    this.businessKnowledgeCtrl.setValue(null);
  }
  // add(event: MatChipInputEvent): void {
  //   // Add only when MatAutocomplete is not open
  //   // To make sure this does not conflict with OptionSelected Event
  //   if (!this.matAutocomplete.isOpen) {
  //     //not sure when this happens?
  //     // const input = event.input;
  //     // const value = event.value;

  //     // console.log(value)

  //     // if ((value || '').trim()) {
  //     //   this.selected_skills.push(value.trim());
  //     // }
  //     // // Reset the input value
  //     // if (input) {
  //     //   input.value = '';
  //     // }

  //     // this.skillsCtrl.setValue(null);
  //   }
  // }

  removeSkill(skill: string): void {
    const index = this.selected_skills.indexOf(skill);
    if (index >= 0) {
      this.selected_skills.splice(index, 1);
    }
  }
  removeBusinessKnowledge(bk: string): void {
    const index = this.selected_business_knowledge.indexOf(bk);
    if (index >= 0) {
      this.selected_business_knowledge.splice(index, 1);
    }
  }
  save() {
    // console.log(this.roleDetails.description.length);
    this.roleDetails.client = this.clientCtrl.value;
    if (this.roleDetails) {
      const hasClientDescription =
        this.roleDetails.hasOwnProperty("client_description");
      const hasInternalNotes =
        this.roleDetails.hasOwnProperty("internal_notes");
      const hasDescription = this.roleDetails.hasOwnProperty("description");
      const hasNYDisclosure = this.roleDetails.hasOwnProperty(
        "new_york_disclosure"
      );

      if (hasClientDescription && this.roleDetails.client_description == null) {
        window.alert(
          "Error saving role. Please fill out the 'Client Description' field."
        );
      } else if (hasInternalNotes && this.roleDetails.internal_notes == null) {
        window.alert(
          "Error saving role. Please fill out the 'Internal Notes' field."
        );
      } else if (hasDescription && this.roleDetails.description == null) {
        window.alert(
          "Error saving role. Please fill out the 'Public Description' field."
        );
      } else if (
        hasNYDisclosure &&
        this.roleDetails.new_york_disclosure == null
      ) {
        window.alert(
          "Error saving role. Please fill out the 'New York Disclosure' field."
        );
      }
    }
    if (this.contactCtrl.value) {
      this.roleDetails.contact_id = this.contactCtrl.value.id;
    } else {
      this.roleDetails.contact_id = null;
    }
    const data = {
      role: this.roleDetails,
      skills: this.selected_skills,
      business_knowledge: this.selected_business_knowledge,
      create: this.create_new,
    };
    this.talentService.saveRole(data).subscribe((result) => {
      this.doneWithSave.emit(true);
    });
  }

  cancel() {
    this.doneWithSave.emit(false);
  }

  private _filterSkill(value: any): string[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase();
      return this.skills
        .filter(
          (n) =>
            this.selected_skills
              .map((selected) => selected.id)
              .indexOf(n.id) === -1
        )
        .filter(
          (skill) => skill.skill.toLowerCase().indexOf(filterValue) === 0
        );
    }
    return this.skills.filter(
      (n) =>
        this.selected_skills.map((selected) => selected.id).indexOf(n.id) === -1
    );
  }

  private _filterBusinessKnowledge(value: any): string[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase();
      return this.business_knowledge
        .filter(
          (n) =>
            this.selected_business_knowledge
              .map((selected) => selected.id)
              .indexOf(n.id) === -1
        )
        .filter((bk) => bk.knowledge.toLowerCase().indexOf(filterValue) === 0);
    }
    return this.business_knowledge.filter(
      (n) =>
        this.selected_business_knowledge
          .map((selected) => selected.id)
          .indexOf(n.id) === -1
    );
  }

  statusChange($event) {
    if ($event.target.value == 2) {
      let title = "Reasons for Closing";
      let reasons = [
        "Filled by SEQ",
        "Filled by Other Vendors",
        "Client Paused Hiring",
        "Client Unresponsive",
      ];
      let confirm_data = { title: title, reasons: reasons, type: "role-close" };
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        maxWidth: "600px",
        data: confirm_data,
      });
      dialogRef.afterClosed().subscribe((note) => {
        this.roleDetails.closed_reason = note;
      });
    }
  }

  getPriorityColor(priority: number): string {
    if(priority == 1){
      return "#ffffff"
    }
    else if (priority >= 2 && priority <= 3) {
      return '#ffe3e3'; // Low priority
    } else if (priority >= 4 && priority <= 7) {
      return '#fbffe1'; // Medium priority
    } else if (priority >= 8 && priority <= 10) {
      return '#e1ffe6'; ; // High priority
    }
    return 'transparent'; // Fallback (no color)
  }

  selectPriority(priority: number) {
    this.roleDetails.priority = priority;
    this.pipelineDropdown = false; // Close dropdown after selection
    console.log('Dropdown state:', this.pipelineDropdown);
  }

  selectPipeline(pipeline: PipelineOption){
    console.log('pipeline', pipeline)
    this.roleDetails.role_pipeline = pipeline.id
    this.pipelineDropdown = false;
  }

  getPipelineColour(targetId: any) : string {
    const matchingOption = this.pipelineOptions.find(option => option.id === targetId);
    if(matchingOption)
      return matchingOption.colour
    return "#ffffff"
  }

  togglePipelineDropdown() {
    this.pipelineDropdown = !this.pipelineDropdown;
  }

  isValidBooleanSearch(query: string | null | undefined): boolean {

    if(!query)
      return true
    // Regular expressions to validate components
    const operatorRegex = /\b(AND|OR|NOT)\b/i;
    const termRegex = /[a-zA-Z0-9_]+/i;
    const validTokenRegex = new RegExp(
        `^(\(|\)|${operatorRegex.source}|${termRegex.source})$`,
        'i'
    );

    // Tokenize the input
    const tokens = query.match(/\(|\)|\bAND\b|\bOR\b|\bNOT\b|[a-zA-Z0-9_]+/gi);

    if (!tokens) {
        return false; // No valid tokens found
    }

    let balance = 0; // To check parentheses balance
    let lastToken = null;

    for (const token of tokens) {
        if (!validTokenRegex.test(token)) {
            return false; // Invalid token
        }

        if (token.toUpperCase() === '(') {
            balance++;
        } else if (token.toUpperCase() === ')') {
            balance--;
            if (balance < 0) {
                return false; // Closing parenthesis without matching opening parenthesis
            }
        }

        // Validate token order
        if (lastToken) {
            if (operatorRegex.test(lastToken) && operatorRegex.test(token)) {
                return false; // Two consecutive operators
            }

            if (lastToken.toUpperCase() === '(' && operatorRegex.test(token)) {
                return false; // Operator immediately after '('
            }

            if (operatorRegex.test(lastToken) && token.toUpperCase() === ')') {
                return false; // ')' immediately after an operator
            }
        }

        lastToken = token;
    }

    // Ensure parentheses are balanced and the last token is valid
    return balance === 0 && !operatorRegex.test(lastToken);
}

}
