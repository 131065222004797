// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  APP_NAME: "yodawe",
  //API_BASE: "https://api.yodawe.com:5004/",
  API_BASE: "https://yodawe.com:5004/",
  COGNITO_CLIENT_ID: "1rdkm979ck48g9ao4cilpe3skk",
  LOGIN_URI:
    "https://yodawe.auth.us-east-1.amazoncognito.com/login?redirect_uri=https://new.yodawe.com/login&response_type=code&client_id=1rdkm979ck48g9ao4cilpe3skk",
  production: true,
  AWS_COGNITO_ACCESS_ID: "AKIA452LO4QZVZLPOYUG",
  AWS_COGNITO_SECRET_ACCESS_KEY: "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",
  AWS_COGNITO_USER_POOL_ID: "us-east-1_hblr9uxLv",
};
